import '@hotwired/turbo-rails'
import * as Turbo from '@hotwired/turbo'
import { Application } from '@hotwired/stimulus'

import Sidebar from '../controllers/sidebar_controller'

const stimulus = Application.start()
stimulus.register('sidebar', Sidebar)

Turbo.setProgressBarDelay(500)
window.Stimulus = stimulus
